import styled from 'styled-components';

import { colors, fontWeights, transition } from 'theme/theme';

export const LargeRadioButtonInput = styled.input<{
  $isTabbing?: boolean;
  $hideRadioButtons?: boolean;
}>`
  width: 100%;
  height: 100%;
  margin: 0;
  border: 0;
  cursor: pointer;

  && {
    appearance: none;
  }
  ${(props) =>
    !props.$isTabbing &&
    `
    &:focus {
      outline: 0;
    }
  `}
  ${(props) =>
    props.$hideRadioButtons &&
    `
    background: transparent;
    border-radius: 0;
  `}
`;
export type LargeRadioButtonContainerProps = {
  $checked?: boolean;
  $alignTop?: boolean;
  $disabled?: boolean;
  $fullWidth?: boolean;
};

export const LargeRadioButtonContainer = styled.div<LargeRadioButtonContainerProps>`
  ${(props) => {
    if (props.$disabled && props.$checked) {
      return `
        --container-bg-color: ${colors.selectionBlue};
        --container-text-color: ${colors.mediumContentGrey};
        --container-opacity: 0.5;
      `;
    }

    if (props.$disabled) {
      return `
        --container-bg-color: ${colors.inputGrey};
        --container-text-color: ${colors.lightContentGrey};
        --container-opacity: 0.5;
      `;
    }

    if (props.$checked) {
      return `
        --container-bg-color: ${colors.selectionBlue};
        --container-text-color: ${colors.mediumContentGrey};
        --container-opacity: 1;
      `;
    }

    return `
        --container-bg-color: ${colors.inputGrey};
        --container-text-color: ${colors.lightContentGrey};
        --container-opacity: 1;
    `;
  }}

  width: ${(props) => (props.$fullWidth ? '100%' : 'fit-content')};

  position: relative;
  display: flex;
  flex: 1;
  align-items: ${(props) => (props.$alignTop ? 'flex-start' : 'center')};
  padding: 20px 24px;
  height: 100%;

  background: var(--container-bg-color);
  color: var(--container-text-color);
  border-radius: 10px;
  transition:
    background ${transition},
    color ${transition},
    opacity 0.5s;
  opacity: var(--container-opacity, 1);

  &:hover {
    --container-bg-color: color-mix(
      in sRGB,
      ${colors.selectionBlue} 60%,
      ${colors.white}
    );
  }

  ${(props) =>
    props.$disabled
      ? ` &, * { pointer-events: none; } `
      : ` * { cursor: pointer; }
  `}

  label {
    font-weight: ${fontWeights.semiBold};
  }
`;

const checkmarkSize = 16;
export const CheckmarkContainer = styled.div<{
  $checked?: boolean;
  $alignTop?: boolean;
}>`
  ${({ $checked }) =>
    $checked &&
    `--checkmark-border-color: ${colors.brandBlue};
    --checkmark-mark-color: ${colors.brandBlue};
  `}

  box-shadow: 0 0 0 1px ${({ $checked }) =>
    $checked ? colors.brandBlue : colors.lightContentGrey};

  display: flex;
  place-content: center;
  place-items: center;
  margin-block-start: ${(props) => (props.$alignTop ? '0.25em' : '0')};
  flex: 0 0 auto;
  width: ${checkmarkSize}px;
  height: ${checkmarkSize}px;
  border-radius: 100rem;
  transition-property: box-shadow, background, opacity;
  transition-duration: ${transition}, ${transition}, 0.5s;

  ${LargeRadioButtonContainer}:hover & {
    box-shadow: 0 0 0 1px ${colors.brandBlue};
  }

  span {
    width: calc(${checkmarkSize}px - 4px);
    height: calc(${checkmarkSize}px - 4px);
    display: block;
    border-radius: 50%;
    background: ${colors.brandBlue};
    scale: ${(props) => (props.$checked ? 1 : 0)};
    transition: scale ${transition};
  }
`;
